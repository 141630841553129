export const fetchLicense = async () => {
  let isLicenseExpired = true
  const response = await fetch(
    `${process.env.REACT_APP_API_LICENSE_URL}/api/v1/license/${process.env.REACT_APP_CLIENT_CODE}`,
    {
      method: "GET",
      headers: { 'Content-Type': 'application/json' }
    }
  );
  if (response.status === 200) {
    const data = await response.json();
    
    isLicenseExpired = data.expired
    let licenseMessage = ''
    if (isLicenseExpired) {
      licenseMessage = "Your license has expired. You can no longer add new projects or audit plans. Please renew your license to continue."
    }
    return {
      'is_license_expired': isLicenseExpired,
      'license_message': licenseMessage,
      data
    }
  } else {
    return {
      'is_license_expired': true,
      'license_message': 'Something went wrong with license, please contact support.',
    }
  }
}
