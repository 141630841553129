import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Slide, toast } from "react-toastify";

// external
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { fetchLicense } from "../../utils/fetchLicense";
import logo from "../../assets/images/bg/logo.png";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "auto",
  marginTop: "10vh",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    width: "60%",
    justifyContent: "space-between",
  },
}));

const WrapperInfo = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: "20px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 0,
  },
}));

const LogoImage = styled("img")(({ theme }) => ({
  width: "60%",
  margin: "20px auto",
  display: "none",
  [theme.breakpoints.up("md")]: {
    width: "100%",
    margin: 0,
    display: "flex",
  },
}));

const LogoImageMobile = styled("img")(({ theme }) => ({
  width: "24%",
  margin: "20px auto",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const InfoSection = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));

const TextSection = styled("p")({
  fontSize: "16px",
  margin: "5px 0",
});

const InputFile = styled("input")({
  display: "none",
});

const UploadButton = styled("label")(({ theme }) => ({
  backgroundColor: "#06a0b1",
  color: "#fff",
  padding: "8px 14px",
  borderRadius: "4px",
  textAlign: "center",
  cursor: "pointer",
  fontSize: "14px",
  marginTop: "10px",
  width: "fit-content",
  ":hover": {
    backgroundColor: "#048593",
  },
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "10px",
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
  },
}));

function License() {
  const token = Cookies.get("access_token") || "";
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);
  const [dataLicense, setDataLicense] = useState([]);

  useEffect(() => {
    const handler = setTimeout(async () => {
      await fetchProfile(token, setDataProfile, setNotAllowAccessPage, [
        "TL",
        "13",
        "14",
      ]);
      const data = await fetchLicense();
      setDataLicense(data.data);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_LICENSE_URL}/api/v1/license/renewal`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        toast.success("The license has been renewed!");
        const data = await fetchLicense();
        setDataLicense(data.data);
      } else if (response.status === 400 || response.status === 413) {
        const errorDetail = await response.json();
        toast.error(errorDetail.detail || "An error occurred");
      } else {
        toast.error("Unexpected error occurred");
      }
    } catch (error) {
      toast.error("Failed to upload file. Please try again later.");
    }
    event.target.value = null;
    setIsLoading(false);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="License"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="License"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <Wrapper>
        <InfoSection>
          <WrapperInfo>
            {" "}
            <h1>SIVINA License Information</h1>
            <LogoImageMobile className="login-logo" src={logo} alt="logo" />
          </WrapperInfo>
          <WrapperInfo>
            <div>
              <TextSection>Client ID</TextSection>
              <TextSection>Client Name</TextSection>
              <TextSection>Expired Date</TextSection>
            </div>
            <div>
              <TextSection>: {dataLicense?.code}</TextSection>
              <TextSection>: {dataLicense?.name}</TextSection>
              <TextSection>
                :{" "}
                {dataLicense?.expiry_date
                  ? new Date(dataLicense.expiry_date).toLocaleDateString(
                      "en-GB",
                      { day: "numeric", month: "long", year: "numeric" }
                    )
                  : ""}
              </TextSection>
            </div>
          </WrapperInfo>
          <ButtonWrapper>
            <InputFile
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              accept="txt"
              disabled={isLoading}
            />
            <UploadButton htmlFor="file-upload" disabled={isLoading}>
              {isLoading ? "Loading..." : "Upload License Key"}
            </UploadButton>
          </ButtonWrapper>
        </InfoSection>
        <div style={{ width: "30%" }}>
          <LogoImage className="login-logo" src={logo} alt="logo" />
        </div>
      </Wrapper>
    </div>
  );
}

export default License;
