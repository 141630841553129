import React, { useState, useEffect } from "react";
import Navbar from "../../../../Components/Navbar";
import { fetchProfile } from "../../../../utils/fetchProfile";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SearchBarSetupArea from "./SearchBarSetupArea";
import TextField from "@mui/material/TextField";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import DataTable from "./DataGridSetup";
import EditIcon from "@mui/icons-material/Edit";
import { modalStyle } from "../../../../Components/Modal/Index";
import { fetchDataStoreMaster, fetchExportDataStoreMaster } from "./DataUtil";
import { ImportModalSetup } from "../../../../Components/Fragments/Modals/ModalSetupStoreImport";
import { exportStoreMaster } from "../../../../services/storeMasterService";
import { Slide, toast } from "react-toastify";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import "../../../../style/setup.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 10px",
  border: "1px solid",
  marginLeft: "10px",
  width: "100px",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function SetUpArea() {
  const accessToken = Cookies.get("access_token") || "";
  const [dataProfile, setDataProfile] = useState([]);
  const [dataArea, setDataArea] = useState([]);
  const [dataRegion, setDataRegion] = useState([]);
  const [dataJenisWilayah, setDataJenisWilayah] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchBarValue, setSearchBarValue] = useState("area");
  const [selectedOption, setSelectedOption] = useState("area");
  const [selectedUpdate, setSelectedUpdate] = useState("area");
  const [modalAddSetup, setModalAddSetup] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedStore, setEditedStore] = useState({});

  const [editStoreId, setEditStoreId] = useState(null);
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedTable, setSelectedTable] = useState("area");
  const [initialText, setInitialText] = useState("");

  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");

  const [totalCountArea, setTotalCountArea] = useState(0);
  const [totalCountRegion, setTotalCountRegion] = useState(0);
  const [totalCountJenisWilayah, setTotalCountJenisWilayah] = useState(0);
  const [pageArea, setPageArea] = useState(0);
  const [pageRegion, setPageRegion] = useState(0);
  const [pageJenisWilayah, setPageJenisWilayah] = useState(0);
  const [rowsPerPageArea, setRowsPerPageArea] = useState(5);
  const [rowsPerPageRegion, setRowsPerPageRegion] = useState(5);
  const [rowsPerPageJenisWilayah, setRowsPerPageJenisWilayah] = useState(5);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
        "13",
        "14",
      ]);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchArea(pageArea, rowsPerPageArea);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, pageArea, rowsPerPageArea]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchRegion(pageRegion, rowsPerPageRegion);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, pageRegion, rowsPerPageRegion]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken, pageJenisWilayah, rowsPerPageJenisWilayah]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchDataStoreMaster(accessToken, setIsLoading, setFilteredRows);
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  async function fetchArea() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type?page=${
          pageArea + 1
        }&size=${rowsPerPageArea}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataArea(data.data);
        setTotalCountArea(data.pagination_info.total);
      }
    } catch (e) {
      console.error(e);
    }
  }
  async function fetchRegion() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/regions?page=${
          pageRegion + 1
        }&size=${rowsPerPageRegion}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataRegion(data.data);
        setTotalCountRegion(data.pagination_info.total);
      } else {
        console.error("Error fetching Jenis Outlet:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching Jenis Outlet:", e);
    }
  }

  async function fetchJenisWilayah() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/wilayah?page=${
          pageJenisWilayah + 1
        }&size=${rowsPerPageJenisWilayah}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setDataJenisWilayah(data.data);
        setTotalCountJenisWilayah(data.pagination_info.total);
      } else {
        console.error("Error fetching Tipe Survey:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching Tipe Survey:", e);
    }
  }

  const handlePageChangeArea = (event, newPage) => {
    setPageArea(newPage);
  };

  const handlePageChangeRegion = (event, newPage) => {
    setPageRegion(newPage);
  };

  const handlePageChangeJenisWilayah = (event, newPage) => {
    setPageJenisWilayah(newPage);
  };

  const handleRowsPerPageChangeArea = (event) => {
    setRowsPerPageArea(parseInt(event.target.value, 10));
    setPageArea(0);
  };

  const handleRowsPerPageChangeRegion = (event) => {
    setRowsPerPageRegion(parseInt(event.target.value, 10));
    setPageRegion(0);
  };

  const handleRowsPerPageChangeJenisWilayah = (event) => {
    setRowsPerPageJenisWilayah(parseInt(event.target.value, 10));
    setPageJenisWilayah(0);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleTableSelection = (table) => {
    setPageArea(0);
    setPageRegion(0);
    setPageJenisWilayah(0);
    setTotalCountArea(0);
    setTotalCountRegion(0);
    setTotalCountJenisWilayah(0);
    setSelectedTable(table);
    setSearchBarValue(table);
    setSelectedOption(table);
    setSelectedUpdate(table);

    setSearchBarValue((prevValue) => {
      refreshData(table);
      return table;
    });
  };

  const [newArea, setNewArea] = useState({
    id: "",
    code: "",
    name: "",
  });
  const [newRegion, setRegion] = useState({
    id: "",
    desc: "",
  });
  const [newJenisWilayah, setNewJenisWilayah] = useState({
    id: "",
    desc: "",
  });

  const handleSetupInputChange = (event) => {
    const { name, value } = event.target;
    setNewArea({
      ...newArea,
      [name]: value,
    });
    setRegion({
      ...newRegion,
      [name]: value,
    });
    setNewJenisWilayah({
      ...newJenisWilayah,
      [name]: value,
    });
  };

  const handleCreateArea = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newArea),
        }
      );

      if (response.status === 201) {
        alert("Store Type berhasil dibuat!");
        setModalAddSetup(false);
        setPageArea(0);
        await fetchArea(pageArea, rowsPerPageArea);
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Store Type gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Store Type:", error);
    }
  };

  const handleCreateRegion = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newRegion),
        }
      );

      if (response.status === 201) {
        alert("Jenis Outlet berhasil dibuat!");
        setModalAddSetup(false);
        setPageRegion(0);
        await fetchRegion(pageRegion, rowsPerPageRegion);
      } else if (response.status === 400) {
        const data = await response.json();
        console.log("Data gagal dibuat. Pesan kesalahan:", data.detail);
        alert(data.detail);
      } else {
        alert("Jenis Outlet gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Jenis Outlet:", error);
    }
  };

  const handleCreateJenisWilayah = async () => {
    const token = Cookies.get("access_token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newJenisWilayah),
        }
      );

      if (response.status === 201) {
        alert("Tipe Survey berhasil dibuat!");
        setModalAddSetup(false);
        setPageJenisWilayah(0);
        await fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
      } else if (response.status === 400) {
        const data = await response.json();
        alert(data.detail);
      } else {
        alert("Tipe Survey gagal dibuat!");
      }
    } catch (error) {
      console.error("Error creating Tipe Survey:", error);
    }
  };

  const handleAddNewSetup = () => {
    setModalAddSetup(true);
  };

  const handleEditClick = async (params) => {
    setEditedStore(params);
    setEditStoreId(params.id);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  const handleUpdateArea = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/store-type/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Store berhasil diubah!");
          setEditModalOpen(false);
          setPageArea(0);
          await fetchArea(pageArea, rowsPerPageArea);
        } else {
          alert("Store gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateRegion = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/jenis-outlet/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Jenis Outlet berhasil diubah!");
          setEditModalOpen(false);
          setPageRegion(0);
          await fetchRegion(pageRegion, rowsPerPageRegion);
        } else {
          alert("Jenis Outlet gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleUpdateJenisWilayah = async () => {
    setIsLoading(true);
    if (editStoreId) {
      const token = Cookies.get("access_token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/tipe-survey/${editStoreId}`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(editedStore),
          }
        );

        if (response.status === 200) {
          alert("Tipe Survey berhasil diubah!");
          setEditModalOpen(false);
          setPageJenisWilayah(0);
          await fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
        } else {
          alert("Tipe Survey gagal diubah!");
        }
      } catch (error) {
        console.error(error);
      }
    }
    setIsLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenDialogImport(false);
    setExcelFile("");
    setFileUrl("");
  };

  async function importStoreMasterTemplate() {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", excelFile);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-master/import/setup/template`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (response.status === 201) {
        toast.success("Import Setup Store berhasil dibuat!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        setOpenDialogImport(false);
        refreshData(searchBarValue);
      } else if (response.status === 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Setup Store Master Error.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toast.error("Ada masalah di excel. Periksa kembali isi excel!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error("Gagal mengimport setup store!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error(
          "Failed to import setup store template:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error importing setup store template:", error);
    }
    setIsLoading(false);
  }

  async function exportStoreMasterTemplate() {
    setIsLoading(true);
    const blob = await exportStoreMaster(
      accessToken,
      "template",
      "api/v1/store-master/export/setup/template/base",
      {}
    );
    if (blob) {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Template Setup Store Master.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      toast.error("Gagal mendownload template setup store!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  }

  const refreshData = (currentSearchBarValue) => {
    switch (currentSearchBarValue) {
      case "area":
        fetchArea(pageArea, rowsPerPageArea);
        break;
      case "region":
        fetchRegion(pageRegion, rowsPerPageRegion);
        break;
      case "jenis_wilayah":
        fetchJenisWilayah(pageJenisWilayah, rowsPerPageJenisWilayah);
        break;
      default:
        break;
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await importStoreMasterTemplate();
    setIsLoading(false);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Setup Store Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Setup Area"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >
          <div>
            <SearchBarSetupArea
              label={"Pilih"}
              data={[
                { label: "Area", value: "area" },
                { label: "Region", value: "region" },
                { label: "Jenis Wilayah", value: "jenis_wilayah" },
              ]}
              onSelectTable={(table) => handleTableSelection(table)}
              value={searchBarValue}
            />
          </div>
          {/* button */}
          <div className="button-setup" style={{ display: "flex", gap: "5px" }}>
            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleAddNewSetup}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Add"}
            </BootstrapButton>
          </div>

          {/* create store */}
          <Modal
            open={modalAddSetup}
            onClose={() => setModalAddSetup(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box
              sx={{
                ...style,
                width: 900,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Tooltip title="Close Modal">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalAddSetup(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              {/* Add your modal content here */}

              {selectedOption === "area" && (
                <div className="area">
                  <h2 style={{ marginBottom: "15px" }}>Create Area</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newArea.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="KODE"
                      name="code"
                      value={newArea.code}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="NAME"
                      name="name"
                      value={newArea.name}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateArea}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "region" && (
                <div className="region">
                  <h2 style={{ marginBottom: "15px" }}>Create Region</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newRegion.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="DESCRIPTION"
                      name="desc"
                      value={newRegion.desc}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateRegion}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedOption === "jenis_wilayah" && (
                <div className="jenis_wilayah">
                  <h2 style={{ marginBottom: "15px" }}>Create Jenis Wilayah</h2>
                  <div>
                    <TextField
                      label="ID"
                      name="id"
                      value={newJenisWilayah.id}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="DESCRIPTION"
                      name="desc"
                      value={newJenisWilayah.desc}
                      onChange={handleSetupInputChange}
                      sx={{ width: "100%", mb: 2 }}
                    />
                  </div>
                  <div>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                      onClick={handleCreateJenisWilayah}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Submit"}
                    </BootstrapButton>
                  </div>
                </div>
              )}
            </Box>
          </Modal>

          <Modal
            open={editModalOpen}
            onClose={handleCloseEditModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box
              sx={modalStyle}
              style={{
                maxHeight: "400px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              {selectedUpdate === "area" && (
                <div className="area">
                  <h2>Edit Area</h2>
                  <div>
                    <TextField
                      name="code"
                      label="Code"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.code ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          code: e.target.value,
                        })
                      }
                    ></TextField>

                    <TextField
                      name="name"
                      label="Name"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.name ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          name: e.target.value,
                        })
                      }
                    ></TextField>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateArea}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "region" && (
                <div className="region">
                  <h2>Edit Region</h2>
                  <div>
                    <TextField
                      name="desc"
                      label="Desc"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.desc ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          desc: e.target.value,
                        })
                      }
                    ></TextField>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateRegion}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}

              {selectedUpdate === "jenis_wilayah" && (
                <div className="jenis_wilayah">
                  <h2>Edit Tipe Survey</h2>
                  <div>
                    <TextField
                      name="desc"
                      label="Desc"
                      sx={{ width: "100%", mb: 2 }}
                      value={editedStore.desc ?? ""}
                      onChange={(e) =>
                        setEditedStore({
                          ...editedStore,
                          desc: e.target.value,
                        })
                      }
                    ></TextField>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button
                      variant="outlined"
                      disableRipple
                      sx={{
                        width: "50%",
                        height: 50,
                        mt: 2,
                        color: "#b34469",
                        borderColor: "#b34469",
                      }}
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </Button>
                    <BootstrapButton
                      variant="contained"
                      disableRipple
                      sx={{ width: "50%", height: 50, mt: 2, mr: 0, ml: 2 }}
                      onClick={handleUpdateJenisWilayah}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "UPDATE"}
                    </BootstrapButton>
                  </div>
                </div>
              )}
            </Box>
          </Modal>
        </div>

        <ImportModalSetup
          isLoading={isLoading}
          open={openDialogImport}
          onClose={handleCloseDialog}
          title={"Import Setup Store Master"}
          buttonTitle={"Upload"}
          fileUrl={fileUrl}
          handleSubmit={handleUpload}
          handleChange={{
            excel: setExcelFile,
            fileUrl: setFileUrl,
          }}
          handleExport={{
            template: exportStoreMasterTemplate,
          }}
        />

        <Paper
          sx={{
            mt: 2,
            ml: 2,
            mr: 2,
            mb: 2,
            border: 2,
            borderColor: "rgb(234,243,248)",
          }}
        >
          {selectedTable === "area" && dataArea && dataArea.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ID
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        KODE
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataArea.length > 0 ? (
                      dataArea.map((params) => (
                        <TableRow key={params.id}>
                          <TableCell align="center">{params.id}</TableCell>
                          <TableCell align="center">{params.code}</TableCell>
                          <TableCell align="center">{params.name}</TableCell>
                          <TableCell align="center">
                            <div>
                              {dataProfile &&
                              (dataProfile.role_code === "A" ||
                                dataProfile.role_code === "COA") ? (
                                <>
                                  <Tooltip
                                    title="Edit Area"
                                    onClick={() => handleEditClick(params)}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Assign"
                                    onClick={() =>
                                      (window.location.href =
                                        "/setup-area/assign")
                                    }
                                  >
                                    <IconButton>
                                      <FileUploadIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Data Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={totalCountArea}
                rowsPerPage={rowsPerPageArea}
                page={pageArea}
                onPageChange={handlePageChangeArea}
                onRowsPerPageChange={handleRowsPerPageChangeArea}
              />
            </>
          ) : selectedTable === "region" &&
            dataRegion &&
            dataRegion.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        KODE
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRegion.length > 0 ? (
                      dataRegion.map((params) => (
                        <TableRow key={params.id}>
                          <TableCell align="center">{params.id}</TableCell>
                          <TableCell align="center">{params.name}</TableCell>
                          <TableCell align="center">
                            <div>
                              {dataProfile &&
                              (dataProfile.role_code === "A" ||
                                dataProfile.role_code === "COA") ? (
                                <>
                                  <Tooltip
                                    title="Edit Region"
                                    onClick={() => handleEditClick(params)}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Data Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={totalCountRegion}
                rowsPerPage={rowsPerPageRegion}
                page={pageRegion}
                onPageChange={handlePageChangeRegion}
                onRowsPerPageChange={handleRowsPerPageChangeRegion}
              />
            </>
          ) : selectedTable === "jenis_wilayah" &&
            dataJenisWilayah &&
            dataJenisWilayah.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        KODE
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "rgb(6,157,174)",
                          color: "#FFF",
                        }}
                        align="center"
                      >
                        ACTION
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataJenisWilayah.length > 0 ? (
                      dataJenisWilayah.map((params) => (
                        <TableRow key={params.id}>
                          <TableCell align="center">{params.id}</TableCell>
                          <TableCell align="center">{params.name}</TableCell>
                          <TableCell align="center">
                            <div>
                              {dataProfile &&
                              (dataProfile.role_code === "A" ||
                                dataProfile.role_code === "COA") ? (
                                <>
                                  <Tooltip
                                    title="Edit JenisWilayah"
                                    onClick={() => handleEditClick(params)}
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              ) : null}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>No Data Found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={totalCountJenisWilayah}
                rowsPerPage={rowsPerPageJenisWilayah}
                page={pageJenisWilayah}
                onPageChange={handlePageChangeJenisWilayah}
                onRowsPerPageChange={handleRowsPerPageChangeJenisWilayah}
              />
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
              >
                {initialText}
              </p>
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default SetUpArea;
