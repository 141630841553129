import React from "react";

import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

// external 
const CancelButton = styled(Button)({
    backgroundColor: "#DE3436",
    color: "white",
    "&:hover": {
      backgroundColor: "#DC1F21",
    },
  });

  const ConfirmButton = styled(Button)({
    backgroundColor: "#469F3F",
    color: "white",
    "&:hover": {
      backgroundColor: "#32932B",
    },
  });

export const ConfirmationModalAssign = ({ open, onClose, textConfirmation, handleConfirm, isLoading }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="view-modal-title"
      aria-describedby="view-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "0px solid #000",
          borderRadius: 5,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="view-modal-title" variant="h6" component="h2">
          {textConfirmation}
        </Typography>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <CancelButton
            onClick={onClose}
            style={{ marginRight: "8px" }}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Tidak"}
          </CancelButton>
          <ConfirmButton onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Loading..." : "Ya"}
          </ConfirmButton>
        </Box>
      </Box>
    </Modal>
  );
};

export const ConfirmationModalUnassign = ({ open, onClose, textConfirmation, handleConfirm, isLoading }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="view-modal-title"
      aria-describedby="view-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "0px solid #000",
          borderRadius: 5,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="view-modal-title" variant="h6" component="h2">
          {textConfirmation}
        </Typography>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <CancelButton
            onClick={onClose}
            style={{ marginRight: "8px" }}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Tidak"}
          </CancelButton>
          <ConfirmButton onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Loading..." : "Ya"}
          </ConfirmButton>
        </Box>
      </Box>
    </Modal>
  );
};


