import Cookies from "js-cookie";
import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Button, TextField, Modal, MenuItem, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import Person4Icon from "@mui/icons-material/Person4";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import TableViewIcon from "@mui/icons-material/TableView";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { Slide, toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import "../style/datasurvey.css"

// External
import Navbar from "../Components/Navbar";
import { fetchProfile } from "../utils/fetchProfile";
import { fetchUserFormatted } from "../utils/fetchUser";
import { ModalConfirmation } from "../Components/Fragments/Modals/ModalGeneral";

// Custom CSS
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

function DataSurvey() {
  //data period
  const [dataPeriode, setDataPeriode] = useState([]);
  const [selectedPeriodeId, setSelectedPeriodeId] = useState(
    localStorage.getItem("selectedPeriodeId") || "all"
  );

  // Initial
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = Cookies.get("access_token") || "";
  const [initialText, setInitialText] = useState("");
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [openDialogConfirmationExport, setOpenDialogConfirmationExport] = useState(false);
  const [viewDataExport, setViewDataExport] = useState({
    title: null,
    process_at: null,
  });

  const [rowModesModel, setRowModesModel] = useState({});
  const [surveyData, setSurveyData] = useState([]);

  const dataAuditStatus = [
    // {
    //   label: "SEMUA",
    //   value: "all",
    // },
    {
      label: "Closed",
      value: "Closed",
    },
    {
      label: "On Progress",
      value: "On Progress",
    },
    {
      label: "Valid",
      value: "Valid",
    },
    {
      label: "Not Started",
      value: "Not Started",
    },
  ];
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  // Handling modal
  const [modalAdd, setModalAdd] = useState(false);

  // Token
  const token = Cookies.get("access_token") || "";

  // Button style
  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: 14,
    padding: "3px 10px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#069DAE",
    fontFamily: ["Roboto"].join(","),
    "&:hover": {
      backgroundColor: "#0696A6",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  });

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Download
  const [selectedDownload, setSelectedDownload] = useState(null);
  const [filterDownload, setFilterDownload] = useState([]);

  async function fetchProjectData(token, periodeCode, setSurveyData) {
    setSurveyData([])
    if (periodeCode && periodeCode !== 'all') {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/project?period=${periodeCode}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          const { data } = await response.json();
          const transformedData = data.map((project) => ({
            value: project.survey_id,
            label: project.project_name,
            type: project.project_type_name,
          }));

          setFilterDownload(transformedData);

          if (transformedData.length > 0) {
            setSelectedSurveyId(transformedData[0].value);
            setSurveyType(transformedData[0].type);
          }
        } else {
          throw new Error("Failed to fetch project data");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, []);
    fetchPeriodeFormatted(accessToken, setDataPeriode);
  }, []);
  useEffect(() => {
    fetchProjectData(accessToken, selectedPeriodeId, setSurveyData);
  }, [selectedPeriodeId]);

  // selected
  const [provinceCode, setProvinceCode] = useState("all");
  const [tipeSurveyCode, setTipeSurveyCode] = useState("");
  const [usernameSurveyorCode, setUsernameSurveyorCode] = useState("");
  const [regencyCode, setRegencyCode] = useState("all");
  const [districtCode, setDistrictCode] = useState("all");
  const [subDistrictCode, setSubDistrictCode] = useState("all");
  const [selectedAuditStatus, setSelectedAuditStatus] = useState([]);

  const provinceInputText = "SEMUA";
  const tipeSurveyInputText = "SEMUA";
  const usernameSurveyorInputText = "SEMUA";
  const [regencyInputText, setRegencyInputText] = useState("SEMUA");
  const [districtInputText, setDistrictInputText] = useState("SEMUA");
  const [subDistrictInputText, setSubDistrictInputText] = useState("SEMUA");

  // all data
  const [provinces, setProvinces] = useState([]);
  const [dataSurveyType, setDataSurveyType] = useState([]);

  const [dataUserSurveyor, setDataUserSurveyor] = useState([]);
  const [dataUserSurveyorOptions, setDataUserSurveyorOptions] = useState([]);
  const [dataUserQcOptions, setDataUserQcOptions] = useState([]);

  const [regencies, setRegencies] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);

  const handlePeriodeIdChange = (event, newValue) => {
    let id = "";
    if (newValue) {
      id = newValue.id;
    }
    setSelectedPeriodeId(id);
    localStorage.setItem("selectedPeriodeId", id);

    setSelectedSurveyId("all");
    localStorage.setItem("selectedSurveyId", "all");
  };

  const setDefaultSelectedPeriode = () => {
    if (dataPeriode.length > 0) {
      // Check if the currently selected periode is still available in the data
      const selectedOption = dataPeriode.find(option => option.id === selectedPeriodeId);
      if (selectedOption) {
        setSelectedPeriodeId(selectedOption.id);
      } else {
        // If the selected option is not available, set the first option as default
        setSelectedPeriodeId(dataPeriode[0].id);
      }
    }
  };

  const setDefaultSelectedProject = () => {
    if (surveyData.length > 0) {
      // Check if the currently selected project is still available in the data
      const selectedOption = surveyData.find(option => option.id === selectedSurveyId);
      if (selectedOption) {
        setSelectedSurveyId(selectedOption.id);
      } else {
        // If the selected option is not available, set the first option as default
        setSelectedSurveyId(surveyData[0].id);
      }
    }
  };

  useEffect(() => {
    setDefaultSelectedPeriode();
    setDefaultSelectedProject();
  }, [dataPeriode]);

  async function fetchPeriodeFormatted(token, setDataPeriode) {
    setDataPeriode([]);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/project/period`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const newArray = data.data.map(item => ({
          label: item.period.toString(), // Mengonversi period menjadi string
          id: item.period.toString(),
        }));
        setDataPeriode(newArray);
      } else {
        setDataPeriode([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchTipeSurvey() {
    try {
      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/store-type`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        const newArray = data.data_tipe_survey.map((item) => ({
          label: item,
          id: item,
        }));
        setDataSurveyType(newArray);
      } else {
        setDataSurveyType();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchTipeSurvey();
  }, []);

  useEffect(() => {
    fetchUserFormatted(accessToken, setDataUserSurveyorOptions, "13");
    fetchUserFormatted(accessToken, setDataUserSurveyor, "13");
    fetchUserFormatted(accessToken, setDataUserQcOptions, "14");
  }, []);

  async function fetchProvinces() {
    try {
      const token = Cookies.get("access_token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/area/provinces`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { data } = await response.json();
        setProvinces(data);
      } else {
        setProvinces();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchProvinces();
  }, []);

  async function fetchRegencies(province_code) {
    if (province_code !== "all") {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/regencies?province_code=${province_code}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setRegencies(data.data);
        } else {
          throw new Error("Ada masalah saat mengambil kabupaten");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchDistricts(province_code, regency_code) {
    if (regency_code !== "all") {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/districts?province_code=${province_code}&regency_code=${regency_code}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setDistricts(data.data);
        } else {
          throw new Error("Error when fetch district");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function fetchSubDistricts(province_code, regency_code, district_code) {
    if (districtCode !== "all") {
      try {
        const token = Cookies.get("access_token");
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/area/sub-districts?province_code=${province_code}&regency_code=${regency_code}&district_code=${district_code}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = await response.json();
          setSubDistricts(data.data);
        } else {
          throw new Error("Error when fetch sub district");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const handleProvinceCodeChange = (event) => {
    const selectedProvinceCode = event.target.value;
    setProvinceCode(selectedProvinceCode);
    setRegencyInputText("SEMUA");
    setRegencyCode("all");
    setDistrictCode("all");
    setSubDistrictCode("all");
  };

  const handleTipeSurveyCodeChange = (event) => {
    const selectedTipeSurveyCode = event.target.value;
    setTipeSurveyCode(selectedTipeSurveyCode);
  };

  const handleUsernameSurveyorCodeChange = (event) => {
    const selectedUsernameSurveyorCode = event.target.value;
    setUsernameSurveyorCode(selectedUsernameSurveyorCode);
  };

  const handleRegencyCodeChange = (event) => {
    const selectedRegencyCode = event.target.value;
    setDistrictInputText("SEMUA");
    setRegencyCode(selectedRegencyCode);
    setDistrictCode("all");
  };

  const handleDistrictCodeChange = (event) => {
    const selectedDistrictCode = event.target.value;
    setSubDistrictInputText("SEMUA");
    setDistrictCode(selectedDistrictCode);
    setSubDistrictCode("all");
  };

  const handleSubDistrictCodeChange = (event) => {
    const selectedSubDistrictCode = event.target.value;
    setSubDistrictCode(selectedSubDistrictCode);
  };

  async function createDataSurveyProcess() {
    setIsLoading(true);
    try {
      const surveyId = selectedSurveyId;
      const selectedLabel = filterDownload.find(
        (option) => option.value === selectedSurveyId
      )?.label;

      const formData = {
        title: `Rawdata ${selectedLabel}`,
        audit_status: selectedAuditStatus.length > 0 ? selectedAuditStatus[0] : "",
        tipe_survey: tipeSurveyCode,
        province_code: provinceCode == "all" ? "" : provinceCode,
        regency_code: regencyCode == "all" ? "" : regencyCode,
        district_code: districtCode == "all" ? "" : districtCode,
        survey_id: surveyId
      }

      // const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/process_queue/data_surveys`, {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/rawdata/process_queue/data_surveys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 201 || response.status === 200 || response.status === 202) {
        toast.success('data akan diproses kedalam antrian', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 400) {
        const message = await response.json();
        toast.error(message?.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else if (response.status === 404) {
        toast.error('data tidak ditemukan', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('ada masalah saat menyimpan data', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      }
    } catch (error) {
      console.error('Error creating process queue:', error);
    }
    setIsLoading(false);
  }

  const handleConfirmationExport = async () => {
    setIsLoading(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/process_queue/data_surveys/${selectedSurveyId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status === 200) {
      const { data } = await response.json();
      setOpenDialogConfirmationExport(true);
      setViewDataExport({
        title: data.data.title ?? "rawdata",
        process_at: data.process_at,
      });
    } else if (response.status === 400) {
      toast.error("Data sedang diproses", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      toast.error("Tidak ditemukan excel, klik Process terlebih dahulu", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setIsLoading(false);
  };

  const handleCloseDialog = () => {
    setOpenDialogConfirmationExport(false);
    // clear data
    setViewDataExport({
      title: "",
      process_at: "",
    });
  };

  // export
  const handleExport = async () => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams();

      // Construct the full URL with query parameters
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/process_queue/data_surveys/export/${selectedSurveyId}` +
        (queryString ? `?${queryString}` : "");

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const selectedLabel = filterDownload.find(
        (option) => option.value === selectedSurveyId
      )?.label;

      if (response.status === 200) {
        const blob = await response.blob();
        const filename = `${selectedLabel}.xlsx`;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      } else if (response.status === 400 || response.status === 404) {
        const data = await response.json();
        toast.error(data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
      } else {
        toast.error('Gagal mengunduh', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        });
        console.error('Failed to export process queue:', response.statusText);
      }
    } catch (error) {
      console.error('Error export process queue:', error);
    }
    setIsLoading(false);
  };

  // read
  async function fetchData(
    token,
    selectedSurveyId,
    selectedProvinceId = "all",
    selectedRegencyId = "all",
    selectedDistrictId = "all",
    selectedSubDistrictId = "all",
    selectedAuditStatus = [],
    selectedTipeSurveyId = "",
    selectedUsernameSurveyorId = "",
    selectedIsNegative = "",
  ) {
    setIsLoading(true);
    if (selectedSurveyId) {
      // Create an empty URLSearchParams object
      const queryParams = new URLSearchParams();

      // Add selectedAuditStatus to the query if it's provided
      if (selectedAuditStatus.length > 0) {
        selectedAuditStatus.forEach((status) => {
          queryParams.append("audit_status", status);
        });
      }

      // Add selectedIsNegative to the query if it's provided
      if (typeof selectedIsNegative === 'boolean') {
        queryParams.append("is_negative", selectedIsNegative.toString());
      }

      // Add selectedSurveyType to the query if it's provided
      if (selectedTipeSurveyId && selectedTipeSurveyId !== "") {
        queryParams.append("tipe_survey", selectedTipeSurveyId);
      }

      // Add selectedSurveyType to the query if it's provided
      if (selectedUsernameSurveyorId && selectedUsernameSurveyorId !== "") {
        queryParams.append("user_name_surveyor", selectedUsernameSurveyorId);
      }

      // Add selectedProvinceId to the query if it's provided
      if (selectedProvinceId && selectedProvinceId !== "all") {
        queryParams.append("province_code", selectedProvinceId);
      }

      // Add selectedRegencyId to the query if it's provided
      if (selectedRegencyId && selectedRegencyId !== "all") {
        queryParams.append("regency_code", selectedRegencyId);
      }

      // Add selectedDistrictId to the query if it's provided
      if (selectedDistrictId && selectedDistrictId !== "all") {
        queryParams.append("district_code", selectedDistrictId);
      }

      // Add selectedSubDistrictId to the query if it's provided
      if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
        queryParams.append("sub_district_code", selectedSubDistrictId);
      }

      // Add selectedSubDistrictId to the query if it's provided
      if (selectedSubDistrictId && selectedSubDistrictId !== "all") {
        queryParams.append("sub_district_code", selectedSubDistrictId);
      }

      // Construct the full URL with query parameters
      const queryString = queryParams.toString();
      const apiUrl =
        `${process.env.REACT_APP_API_URL}/api/v1/survey/store/${selectedSurveyId}` +
        (queryString ? `?${queryString}` : "");

      try {
        setSurveyData([]);
        setInitialText("Loading...");
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const { data } = await response.json();
          setInitialText("");
          setSurveyData(data);
        } else if (response.status === 404) {
          setSurveyData([]);
          // Handle error
          setInitialText("No data found");
        }
      } catch (error) {
        // Handle error
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchRegencies(provinceCode);
    fetchDistricts(provinceCode, regencyCode);
    fetchSubDistricts(provinceCode, regencyCode, districtCode);
    // Check if a survey ID is selected before making the API call
    if (selectedSurveyId) {
      const handler = setTimeout(() => {
        fetchData(
          accessToken,
          selectedSurveyId,
          provinceCode,
          regencyCode,
          districtCode,
          subDistrictCode,
          selectedAuditStatus,
          tipeSurveyCode,
          usernameSurveyorCode,
          selectedPeriodeId
        );
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [
    accessToken,
    selectedSurveyId,
    provinceCode,
    regencyCode,
    districtCode,
    subDistrictCode,
    selectedAuditStatus,
    tipeSurveyCode,
    usernameSurveyorCode,
    selectedPeriodeId,
  ]);

  // Handle change in the dropdown selection
  const handleProjectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedSurveyId(selectedId);

    const selectedProject = filterDownload.find(
      (project) => project.value === selectedId
    );

    if (selectedProject) {
      setSurveyType((prevSurveyType) => selectedProject.type);
    } else {
      console.error("Project not found");
    }

    // Reset other state variables
    setRegencies([]);
    setDistricts([]);
    setSubDistricts([]);
    setProvinceCode("all");
    setRegencyCode("all");
    setDistrictCode("all");
    setSubDistrictCode("all");
    setRegencyInputText("SEMUA");
    setDistrictInputText("SEMUA");
    setSubDistrictInputText("SEMUA");
  };

  const handleAuditStatusChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAuditStatus(
      typeof value === "string" ? value.split(",") : value
    );
    localStorage.setItem("selectedAuditStatus", value);
  };

  // view trx
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenQc, setModalOpenQc] = useState(false);
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenLogCorrection, setModalOpenLogCorrection] = useState(false);

  const [selectedModalStoreCode, setSelectedModalStoreCode] = useState("");

  const [selectedProductCode, setSelectedProductCode] = useState("");

  const [modalTableData, setModalTableData] = useState([]);
  const [modalTableDataQc, setModalTableDataQc] = useState([]);

  const [selectedSurveyType, setSelectedSurveyType] = useState("");
  const [selectedStatusAudit, setSelectedStatusAudit] = useState("");

  const [surveyType, setSurveyType] = useState("");

  const openModal = (storeCode, statusCode, surveyType) => {
    setModalOpen(true);
    setSelectedModalStoreCode(storeCode);
    setSelectedStatusAudit(statusCode);
    setSelectedSurveyType(surveyType);
  };

  const openModalQc = (storeCode, surveyType) => {
    setModalOpenQc(true);
    setSelectedModalStoreCode(storeCode);
    setSelectedSurveyType(surveyType);
  };

  const openModalEdit = (storeCode, productCode, surveyType) => {
    setModalOpenEdit(true);
    setSelectedModalStoreCode(storeCode);
    setSelectedProductCode(productCode);
    setSelectedSurveyType(surveyType);
  };

  const openModalLogCorrection = (storeCode, productCode) => {
    setModalOpenLogCorrection(true);
    setSelectedModalStoreCode(
      viewTrxInfoForLog.find((item) => item.label === "Kode Toko").value
    );
    setSelectedProductCode(
      viewTrxInfoForLog.find((item) => item.label === "Kode Produk").value
    );
  };

  const fetchViewTrx = async () => {
    try {
      let urlViewTrx = `${process.env.REACT_APP_API_URL}/api/v1/trx/rawdata/${selectedSurveyId}/${selectedModalStoreCode}?is_qc=false`;

      const response = await fetch(urlViewTrx, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { data, detail } = await response.json();
        setModalTableData(data);
      } else {
        setModalTableData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchViewTrxQc = async () => {
    try {
      let urlViewTrx = `${process.env.REACT_APP_API_URL}/api/v1/trx/rawdata/${selectedSurveyId}/${selectedModalStoreCode}?is_qc=true`;

      const response = await fetch(urlViewTrx, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { data, detail } = await response.json();
        setModalTableDataQc(data);
      } else {
        setModalTableDataQc([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (modalOpen) {
      fetchViewTrx();
    }
    if (modalOpenQc) {
      fetchViewTrxQc();
    }
  }, [modalOpen, selectedModalStoreCode]);

  // view trx edit
  const [viewTrxEdit, setViewTrxEdit] = useState([]);
  const [viewTrxInfo, setViewTrxInfo] = useState([]);
  const [viewTrxInfoForLog, setViewTrxInfoForLog] = useState([]);
  const [viewTrxLog, setViewTrxLog] = useState([]);
  const [editKeyValue, setEditKeyValue] = useState([]);

  const fetchViewTrxEdit = async () => {
    try {
      const urlViewTrx = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: selectedSurveyId,
        store_code: selectedModalStoreCode,
        product_code: selectedProductCode,
      };

      const response = await fetch(urlViewTrx, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const { data, data_info } = await response.json();

        const infoData = [
          { label: "Nama Toko", value: data_info.store_name },
          { label: "Kode Produk", value: data_info.product_code },
          { label: "Nama Produk", value: data_info.product_name },
        ];

        const infoDataForLog = [
          { label: "Kode Toko", value: data_info.store_code },
          { label: "Kode Produk", value: data_info.product_code },
        ];

        const editKey = [{ label: "Edit Key", value: data.fact }];

        const displayedData = data.map((item) => ({
          fact: item.fact,
          QC: item.QC,
          Surveyor: item.Surveyor,
        }));

        displayedData.forEach((item) => {
          if (
            item.fact === "StatusPenjualan" &&
            item.Surveyor &&
            item.Surveyor.answer
          ) {
            item.Surveyor = item.Surveyor.answer;
          }

          if (
            item.fact === "TipeStokToko" &&
            item.Surveyor &&
            item.Surveyor.length > 0 &&
            item.Surveyor[0].answer
          ) {
            item.Surveyor = item.Surveyor.map((stok) => stok.answer).join(", ");
          }
        });

        setEditKeyValue(editKey);
        setViewTrxInfo(infoData);
        setViewTrxInfoForLog(infoDataForLog);
        setViewTrxEdit(displayedData);
      } else {
        setViewTrxInfo([]);
        setViewTrxEdit([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (modalOpenEdit) {
      fetchViewTrxEdit();
    }
  }, [modalOpenEdit, selectedModalStoreCode, selectedProductCode]);

  const [dataTipeStokToko, setDataTipeStokToko] = useState([]);
  const [dataStatusPenjualan, setDataStatusPenjualan] = useState([]);

  const rdcEditOptions = async () => {
    try {
      let urlViewEditOptionsRdc = `${process.env.REACT_APP_API_URL}/api/v1/trx/rawdata-product-rdc-list`;

      const response = await fetch(urlViewEditOptionsRdc, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { data_tipe_stok_toko, data_status_penjualan } =
          await response.json();

        const processedDataTipeStokToko = data_tipe_stok_toko.map((item) => ({
          label: item.answer,
          value: item.code,
        }));
        setDataTipeStokToko(processedDataTipeStokToko);

        const processedDataStatusPenjualan = data_status_penjualan.map(
          (item) => ({
            label: item.answer,
            value: item.code,
          })
        );
        setDataStatusPenjualan(processedDataStatusPenjualan);
      } else {
        setDataStatusPenjualan([]);
        setDataTipeStokToko([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    rdcEditOptions();
  }, []);

  const fetchViewTrxLog = async () => {
    try {
      const urlViewTrx = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: selectedSurveyId,
        store_code: selectedModalStoreCode,
        product_code: selectedProductCode,
      };

      const response = await fetch(urlViewTrx, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const { data, data_info } = await response.json();
        setViewTrxLog(data);
      } else {
        setViewTrxLog([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (modalOpenLogCorrection) {
      fetchViewTrxLog();
    }
  }, [modalOpenLogCorrection, selectedModalStoreCode, selectedProductCode]);

  const columns = [
    // {
    //   field: "is_negative",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "IS NEGATIVE",
    //   width: 150,
    //   align: "center", // Mengatur align ke center
    //   headerAlign: "center",
    //   editable: false,
    //   renderCell: (params) => {
    //     const isNegative = params.row.is_negative;
  
    //     return (
    //       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //         {isNegative ? (
    //           <CheckCircleIcon style={{ color: 'green' }} />
    //         ) : (
    //           <CancelIcon style={{ color: 'red' }} />
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "store_id",
      headerClassName: "super-app-theme--header",
      headerName: "KODE OUTLET",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "store_name",
      headerClassName: "super-app-theme--header",
      headerName: "NAMA TOKO",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "store_type",
      headerClassName: "super-app-theme--header",
      headerName: "TIPE TOKO",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "audit_status",
      headerClassName: "super-app-theme--header",
      headerName: "STATUS",
      width: 130,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => {
        let statusText = "";

        switch (params.value) {
          case "Valid":
            statusText = (
              <div
                style={{
                  background: "lightblue",
                  color: "white",
                  padding: "10px",
                }}
              >
                Valid
              </div>
            );
            break;
          case "Not Started":
            statusText = (
              <div
                style={{ background: "red", color: "white", padding: "10px" }}
              >
                Not Started
              </div>
            );
            break;
          case "Closed":
            statusText = (
              <div
                style={{ background: "green", color: "white", padding: "10px" }}
              >
                Closed
              </div>
            );
            break;
          case "On Progress":
            statusText = (
              <div
                style={{
                  background: "orange",
                  color: "white",
                  padding: "10px",
                }}
              >
                On Progress
              </div>
            );
            break;
          default:
            statusText = params.value;
            break;
        }

        return statusText;
      },
    },
    {
      field: "user_name_surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "USERNAME SURVEYOR",
      width: 180,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "date_uploaded",
      headerClassName: "super-app-theme--header",
      headerName: "DATE UPLOADED",
      width: 150,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "start_auditing",
      headerClassName: "super-app-theme--header",
      headerName: "START AUDITING",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "province_name",
      headerClassName: "super-app-theme--header",
      headerName: "PROVINSI",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "regency_name",
      headerClassName: "super-app-theme--header",
      headerName: "KOTA/KABUPATEN",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "district_name",
      headerClassName: "super-app-theme--header",
      headerName: "KECAMATAN",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "sub_district_name",
      headerClassName: "super-app-theme--header",
      headerName: "KELURAHAN",
      width: 160,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "qc_data_exist",
      headerClassName: "super-app-theme--header",
      headerName: "QC Data",
      width: 180,
      align: "center",
      headerAlign: "center",
      editable: false,
      renderCell: (params) => {
        let qcDataExist = "";

        switch (params.row.qc_data_exist) {
          case true:
            qcDataExist = (
              <Tooltip
                title="View QC Data"
                onClick={() => openModalQc(params.row.store_id)}
              >
                <IconButton>
                  <BackupTableIcon />
                </IconButton>
              </Tooltip>
            );
            break;
          default:
            qcDataExist = "";
            break;
        }

        return qcDataExist;
      },
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor Data",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        const { store_id, audit_status } = params.row;

        const handleViewDataTrx = () => {
          openModal(store_id, audit_status);
        };

        return (
          <Tooltip title="View Data Trx" onClick={handleViewDataTrx}>
            <IconButton>
              <TableViewIcon />
            </IconButton>
          </Tooltip>
        );
      },
    }

  ];

  const columns_edit_rak1 = [
    {
      field: "fact",
      headerClassName: "super-app-theme--header",
      headerName: "FACT",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "QC",
      headerClassName: "super-app-theme--header",
      headerName: "QC",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "Surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        let tooltipContent;

        if (
          params.row.fact === "TotalStok" ||
          params.row.fact === "Penjualan"
        ) {
          tooltipContent = <IconButton></IconButton>;
        } else {
          tooltipContent = (
            <Tooltip
              title="Edit Data"
              onClick={() => openModalEditFunction(params.row.fact)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        }

        return tooltipContent;
      },
    },
  ];

  const columns_edit_rak2 = [
    {
      field: "fact",
      headerClassName: "super-app-theme--header",
      headerName: "FACT",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "QC",
      headerClassName: "super-app-theme--header",
      headerName: "QC",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "Surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        let tooltipContent;

        if (
          params.row.fact === "TotalStok" ||
          params.row.fact === "Penjualan" ||
          params.row.fact === "Keterangan"
        ) {
          tooltipContent = <IconButton></IconButton>;
        } else {
          tooltipContent = (
            <Tooltip
              title="Edit Data"
              onClick={() => openModalEditFunction(params.row.fact)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        }

        return tooltipContent;
      },
    },
  ];

  const columns_edit_rdc = [
    {
      field: "fact",
      headerClassName: "super-app-theme--header",
      headerName: "FACT",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "QC",
      headerClassName: "super-app-theme--header",
      headerName: "QC",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "Surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor",
      width: 380,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        let tooltipContent;

        if (params.row.fact === "Keterangan") {
          tooltipContent = <IconButton></IconButton>;
        } else {
          tooltipContent = (
            <Tooltip
              title="Edit Data"
              onClick={() => openModalEditFunction(params.row.fact)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          );

          if (params.row.fact === "StatusPenjualan") {
            tooltipContent = (
              <Tooltip
                title="Edit Data"
                onClick={() => openModalEditFunctionStatus(params.row.fact)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            );
          }

          if (params.row.fact === "TipeStokToko") {
            tooltipContent = (
              <Tooltip
                title="Edit Data"
                onClick={() => openModalEditFunctionTipeStok(params.row.fact)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            );
          }
        }

        return tooltipContent;
      },
    },
  ];

  const [columnsView, setColumnsView] = useState([]);

  useEffect(() => {
    if (modalTableData.length > 0) {
      const dynamicColumns = Object.keys(modalTableData[0])
        .filter((key) => key !== "id")
        .map((key) => ({
          field: key,
          headerClassName: "super-app-theme--header",
          headerName: key.toUpperCase(),
          width: 200,
          align: "left",
          headerAlign: "center",
          editable: false,
        }));

      const userRole = dataProfile.role_code;

      if (selectedStatusAudit === "Closed" && userRole !== "TL" && selectedSurveyId != '65cc7b99e0747712517ed5e3') {
        dynamicColumns.push({
          field: "actions",
          headerClassName: "super-app-theme--header",
          headerName: "ACTIONS",
          headerAlign: "center",
          align: "center",
          width: 200,
          renderCell: (params) => {
            const { store_code, product_code } = params.row;

            const handleViewDataEdit = () => {
              openModalEdit(store_code, product_code);
            };

            return (
              <Tooltip title="View Data Edit" onClick={handleViewDataEdit}>
                <IconButton>
                  <BackupTableIcon />
                </IconButton>
              </Tooltip>
            );
          },
        });
      }

      setColumnsView(dynamicColumns);
    }
  }, [modalTableData, selectedStatusAudit, dataProfile]);

  const [columnsViewQc, setColumnsViewQc] = useState([]);

  useEffect(() => {
    if (modalTableDataQc.length > 0) {
      const dynamicColumns = Object.keys(modalTableDataQc[0])
        .filter((key) => key !== "id")
        .map((key) => {
          return {
            field: key,
            headerClassName: "super-app-theme--header",
            headerName: key.toUpperCase(),
            width: 200,
            align: "left",
            headerAlign: "center",
            editable: false,
          };
        });
      setColumnsViewQc(dynamicColumns);
    }
  }, [modalTableDataQc]);

  const handleEditRowFunction = async (fact, newValue) => {
    try {
      const editUrl = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: selectedSurveyId,
        store_code: selectedModalStoreCode,
        product_code: selectedProductCode,
        key: fact,
        value: newValue,
      };

      const response = await fetch(editUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        alert("Data berhasil diubah!");
        setModalOpenEditFunction(false);
        setModalOpenEditFunctionRdcTipe(false);
        setModalOpenEditFunctionRdcStatus(false);
        setEditValue(null);
        await fetchViewTrxEdit();
      } else if (response.status === 422) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 400 || response.status === 404) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 500) {
        alert("Internal Server Error");
      } else {
        alert("Gagal mengubah data!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditRowFunctionTipeStok = async (fact2, newValue2) => {
    try {
      const editUrl = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: selectedSurveyId,
        store_code: selectedModalStoreCode,
        product_code: selectedProductCode,
        key: fact2,
        value: newValue2,
      };

      const response = await fetch(editUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        alert("Data berhasil diubah!");
        setModalOpenEditFunctionRdcTipe(false);
        await fetchViewTrxEdit();
      } else if (response.status === 422) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 400) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 404) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 500) {
        alert("Internal Server Error");
      } else {
        alert("Gagal mengubah data!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [editKey, setEditKey] = useState("");
  const [editValue, setEditValue] = useState("");

  const [editKey2, setEditKey2] = useState("");
  const [editValue2, setEditValue2] = useState([]);

  const [modalOpenEditFunction, setModalOpenEditFunction] = useState(false);
  const [modalOpenEditFunctionRdcTipe, setModalOpenEditFunctionRdcTipe] =
    useState(false);
  const [modalOpenEditFunctionRdcStatus, setModalOpenEditFunctionRdcStatus] =
    useState(false);

  const openModalEditFunction = (fact) => {
    setEditKey(fact);

    setModalOpenEditFunction(true);
  };

  const openModalEditFunctionTipeStok = (fact) => {
    setEditKey2(fact);

    setModalOpenEditFunctionRdcTipe(true);
  };

  const openModalEditFunctionStatus = (fact) => {
    setEditKey(fact);

    setModalOpenEditFunctionRdcStatus(true);
  };

  const [columnsViewLog, setColumnsViewLog] = useState([]);

  useEffect(() => {
    if (viewTrxLog.length > 0) {
      const dynamicColumns = Object.keys(viewTrxLog[0])
        .filter((key) => key !== "id") // Exclude the column with field named "id"
        .map((key) => {
          return {
            field: key,
            headerClassName: "super-app-theme--header",
            headerName: key.toUpperCase(),
            width: 200,
            align: "left",
            headerAlign: "center",
            editable: false,
          };
        });

      setColumnsViewLog(dynamicColumns);
    }
  }, [viewTrxLog]);

  const [logCorrection, setLogCorrection] = useState(null);

  const handleLogCorrectionButtonClick = () => {
    setSelectedModalStoreCode(
      viewTrxInfoForLog.find((item) => item.label === "Kode Toko").value
    );
    setSelectedProductCode(
      viewTrxInfoForLog.find((item) => item.label === "Kode Produk").value
    );
    setLogCorrection("log-correction");
  };

  const handleChangeTipeStokToko = (event) => {
    const {
      target: { value },
    } = event;
    setEditValue2(typeof value === "string" ? value.split(",") : value);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Data Survey"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Data Survey"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        <div
          className="legend"
        >
          {/* Search bar */}
          <div>
            <div
              className="searchbar"
            >
              <TextField
                id="periode-autocomplete"
                select
                label="Periode"
                value={selectedPeriodeId}
                onChange={(event) => handlePeriodeIdChange(event, { id: event.target.value })}
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
              >
                {dataPeriode.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-currency"
                label="Project - Survey"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={selectedSurveyId}
                onChange={handleProjectChange}
              >
                {filterDownload.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <FormControl sx={{ height: 40, mt: 1, mr: 1 }}>
                <InputLabel sx={{ mt: "-5px" }} id="demo-multiple-name-label">
                  Status
                </InputLabel>
                <Select
                  multiple
                  sx={{ height: 40 }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedAuditStatus}
                  onChange={handleAuditStatusChange}
                  input={<OutlinedInput label="Status" />}
                >
                  {dataAuditStatus.map((data) => (
                    <MenuItem key={data.value} value={data.value}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Tipe Survey"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
                value={tipeSurveyCode}
                onChange={handleTipeSurveyCodeChange}
              >
                <MenuItem key={"x"} value={""}>
                  {tipeSurveyInputText}
                </MenuItem>
                {dataSurveyType.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Full Name Surveyor"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
                value={usernameSurveyorCode}
                onChange={handleUsernameSurveyorCodeChange}
              >
                <MenuItem key={"x"} value={""}>
                  {usernameSurveyorInputText}
                </MenuItem>
                {dataUserSurveyor.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Provinsi"
                select
                sx={{
                  mr: 1,
                  height: 40,
                  mt: 1,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={provinceCode}
                onChange={handleProvinceCodeChange}
              >
                <MenuItem key={"x"} value={"all"}>
                  {provinceInputText}
                </MenuItem>
                {provinces.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Kota/Kab"
                select
                sx={{
                  mr: 1,
                  height: 40,
                  mt: 1,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={regencyCode}
                onChange={handleRegencyCodeChange}
              >
                <MenuItem key={"x"} value={"all"}>
                  {regencyInputText}
                </MenuItem>
                {regencies.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Kec"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                  // border: 3,
                  // borderRadius: 2,
                  // borderColor: "#069dae",
                }}
                size="small"
                value={districtCode}
                onChange={handleDistrictCodeChange}
              >
                <MenuItem key={"x"} value={"all"}>
                  {districtInputText}
                </MenuItem>
                {districts.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                id="outlined-select-currency"
                label="Kel"
                select
                sx={{
                  mr: 1,
                  mt: 1,
                  height: 40,
                }}
                size="small"
                value={subDistrictCode}
                onChange={handleSubDistrictCodeChange} // Add this line to call the function
              >
                <MenuItem key={"x"} value={"all"}>
                  {subDistrictInputText}
                </MenuItem>
                {subDistricts.map((item) => (
                  <MenuItem key={item.code} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <ModalConfirmation
            open={openDialogConfirmationExport}
            onClose={handleCloseDialog}
            title={'Konfirmasi Download'}
            confirmationText={'Yes'}
            description={`Ditemukan ${viewDataExport.title} terakhir diproses pada ${viewDataExport.process_at}. Apakah anda yakin ingin download rawdata ini?`}
            isLoading={isLoading}
            handleClick={handleExport}
          />
          {/* Button Add New Project */}
          <div style={{ display: "flex", justifyContent: "end" }}>

            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={createDataSurveyProcess}
              disabled={isLoading}
              className="button-process"
              sx={{ mr: 1 }}
            >
              {isLoading ? "Loading..." : "Process"}
            </BootstrapButton>

            <BootstrapButton
              variant="contained"
              disableRipple
              onClick={handleConfirmationExport}
              disabled={isLoading}
              className="button-download"
              sx={{ mr: 1 }}
            >
              {isLoading ? "Loading..." : "Download"}
            </BootstrapButton>
          </div>
          {/* view trx */}
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 1300,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close View Data">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpen(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{ marginLeft: "20px" }}>View Data</h2>
              {modalTableData.length > 0 ? (
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={modalTableData}
                    columns={columnsView}
                    getRowId={(row) => row.id}
                    sx={{
                      mt: 2,
                      ml: 2,
                      mr: 2,
                      "& .super-app-theme--header": {
                        backgroundColor: "rgb(6,157,174)",
                        color: "#FFF",
                        border: 2,
                        fontWeight: "bold",
                        borderColor: "rgb(234,243,248)",
                      },
                      border: 2,
                      borderColor: "rgb(234,243,248)",
                      fontSize: 15,
                    }}
                    getRowHeight={() => 'auto'}
                  />
                </div>
              ) : (
                <div>
                  <p style={{ marginLeft: "20px" }}>Data Kosong</p>
                </div>
              )}
            </Box>
          </Modal>
          {/* view trx qc */}
          <Modal
            open={modalOpenQc}
            onClose={() => setModalOpenQc(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 1300,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close View QC Data">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpenQc(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{ marginLeft: "20px" }}>View QC Data</h2>
              {modalTableDataQc.length > 0 ? (
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={modalTableDataQc}
                    columns={columnsViewQc}
                    getRowId={(row) => row.product_code}
                    getRowHeight={() => 'auto'}
                    sx={{
                      mt: 2,
                      ml: 2,
                      mr: 2,
                      "& .super-app-theme--header": {
                        backgroundColor: "rgb(6,157,174)",
                        color: "#FFF",
                        border: 2,
                        fontWeight: "bold",
                        borderColor: "rgb(234,243,248)",
                      },
                      border: 2,
                      borderColor: "rgb(234,243,248)",
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p style={{ marginLeft: "20px" }}>Data Kosong</p>
                </div>
              )}
            </Box>
          </Modal>
          {/* view table for edit */}
          <Modal
            open={modalOpenEdit}
            onClose={() => setModalOpenEdit(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 1300,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close View Edit">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpenEdit(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{ marginLeft: "20px" }}>View Edit</h2>
              {viewTrxEdit.length > 0 ? (
                <div style={{ height: 600, width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      {viewTrxInfo.map((item) => (
                        <p key={item.label} style={{ marginLeft: "20px" }}>
                          {item.label}: {item.value}
                        </p>
                      ))}
                    </div>
                    <BootstrapButton
                      style={{
                        color: "#fff",
                        height: "40px",
                        fontWeight: "600",
                      }}
                      onClick={openModalLogCorrection}
                    >
                      Log Correction
                    </BootstrapButton>
                  </div>
                  <DataGrid
                    rows={viewTrxEdit}
                    columns={
                      surveyType === "RAK1"
                        ? columns_edit_rak1
                        : surveyType === "RAK2"
                          ? columns_edit_rak2
                          : surveyType === "RDC" || surveyType === "RDC_MT" || surveyType === "RDC_PHARMACY"
                            ? columns_edit_rdc
                            : []
                    }
                    getRowId={(row) => row.fact}
                    getRowHeight={() => 'auto'}
                    sx={{
                      mt: 2,
                      ml: 2,
                      mr: 2,
                      "& .super-app-theme--header": {
                        backgroundColor: "rgb(6,157,174)",
                        color: "#FFF",
                        border: 2,
                        fontWeight: "bold",
                        borderColor: "rgb(234,243,248)",
                      },
                      border: 2,
                      borderColor: "rgb(234,243,248)",

                    }}
                  />
                </div>
              ) : (
                <div>
                  <p style={{ marginLeft: "20px" }}>Data Kosong</p>
                </div>
              )}
            </Box>
          </Modal>
          {/* view table for edit */}
          <Modal
            open={modalOpenEditFunction}
            onClose={() => setModalOpenEditFunction(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 510,
                margin: "0 auto",
                maxHeight: "500px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close View Edit">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpenEditFunction(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{}}>Masukan Nilai Baru</h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  id="outlined-select-currency"
                  label="Key"
                  value={editKey}
                  sx={{
                    width: 250,
                    height: 40,
                    color: "#000",
                  }}
                  size="small"
                  disabled
                />
                <TextField
                  id="outlined-select-currency"
                  label="Value"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  sx={{
                    width: 250,
                    height: 40,
                  }}
                  size="small"
                />
              </div>
              <BootstrapButton
                style={{ width: "100%", height: "40px", marginTop: "10px" }}
                variant="contained"
                onClick={() => {
                  handleEditRowFunction(editKey, editValue);
                  setModalOpenEditFunction(false);
                }}
              >
                Submit
              </BootstrapButton>
            </Box>
          </Modal>
          {/* view table for edit rdc tipe stok */}
          <Modal
            open={modalOpenEditFunctionRdcTipe}
            onClose={() => setModalOpenEditFunctionRdcTipe(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 510,
                margin: "0 auto",
                maxHeight: "500px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close View Edit">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpenEditFunctionRdcTipe(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{}}>Masukan Nilai Baru</h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  id="outlined-select-currency"
                  label="Key"
                  value={editKey2}
                  sx={{
                    width: 250,
                    height: 40,
                    color: "#000",
                  }}
                  size="small"
                  disabled
                />
                <FormControl sx={{ width: "100%", mb: 2, height: "40px", ml: "10px" }}>
                  <InputLabel id="demo-multiple-name-label-group">
                    Value
                  </InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="demo-multiple-name-label-group"
                    id="demo-multiple-name-group"
                    multiple
                    value={editValue2}
                    onChange={handleChangeTipeStokToko}
                    input={<OutlinedInput label="Value" />}
                  >
                    {dataTipeStokToko.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <BootstrapButton
                style={{ width: "100%", height: "40px", marginTop: "10px" }}
                variant="contained"
                onClick={() => {
                  handleEditRowFunctionTipeStok(editKey2, editValue2);
                  setModalOpenEditFunctionRdcTipe(false);
                }}
              >
                Submit
              </BootstrapButton>
            </Box>
          </Modal>
          {/* view table for edit rdc status penjualan */}
          <Modal
            open={modalOpenEditFunctionRdcStatus}
            onClose={() => setModalOpenEditFunctionRdcStatus(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 510,
                margin: "0 auto",
                maxHeight: "500px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close View Edit">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpenEditFunctionRdcStatus(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{}}>Masukan Nilai Baru</h2>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  id="outlined-select-currency"
                  label="Key"
                  value={editKey}
                  sx={{
                    width: 250,
                    height: 40,
                    color: "#000",
                  }}
                  size="small"
                  disabled
                />
                <TextField
                  select
                  id="outlined-select-currency"
                  label="Value"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                  sx={{
                    width: 250,
                    height: 40,
                  }}
                  size="small"
                >
                  {dataStatusPenjualan.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <BootstrapButton
                style={{ width: "100%", height: "40px", marginTop: "10px" }}
                variant="contained"
                onClick={() => {
                  handleEditRowFunction(editKey, editValue);
                  setModalOpenEditFunction(false);
                }}
              >
                Submit
              </BootstrapButton>
            </Box>
          </Modal>
          {/* view log correction */}
          <Modal
            open={modalOpenLogCorrection}
            onClose={() => setModalOpenLogCorrection(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...style,
                width: 1300,
                margin: "0 auto",
                maxHeight: "700px",
                overflow: "auto",
                overflowX: "hidden",
              }}
            >
              <Tooltip title="Close Log Correction">
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => setModalOpenLogCorrection(false)}
                  sx={{ position: "absolute", top: 20, right: 30 }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <h2 style={{ marginLeft: "20px" }}>Log Correction</h2>
              {viewTrxLog.length > 0 ? (
                <div style={{ height: 600, width: "100%" }}>
                  <DataGrid
                    rows={viewTrxLog}
                    columns={columnsViewLog}
                    getRowId={(row) => row.id}
                    getRowHeight={() => 'auto'}
                    sx={{
                      mt: 2,
                      ml: 2,
                      mr: 2,
                      "& .super-app-theme--header": {
                        backgroundColor: "rgb(6,157,174)",
                        color: "#FFF",
                        border: 2,
                        fontWeight: "bold",
                        borderColor: "rgb(234,243,248)",
                      },
                      border: 2,
                      borderColor: "rgb(234,243,248)",
                      fontSize: 15,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <p style={{ marginLeft: "20px" }}>Data Kosong</p>
                </div>
              )}
            </Box>
          </Modal>
          {/* Modal Add New Project */}
          <Modal
            open={modalAdd}
            onClose={() => setModalAdd(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ border: 0 }}
          >
            <Box sx={style}>
              <TextField
                id="outlined-select-currency"
                label="Download - Project Type"
                select
                sx={{ width: 400 }}
                size="small"
                value={selectedDownload}
                onChange={(event) => setSelectedDownload(event.target.value)}
              >
                {filterDownload.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* File Input */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: 400, mt: 2, height: 50 }}
                // onClick={handleDownload}
                >
                  Download
                </BootstrapButton>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      {surveyData.length > 0 ? (
        <div>
          <DataGrid
            sx={{
              mt: 2,
              ml: 2,
              mr: 2,
              "& .super-app-theme--header": {
                backgroundColor: "rgb(6,157,174)",
                color: "#FFF",
                border: 2,
                fontWeight: "bold",
                borderColor: "rgb(234,243,248)",
              },
              border: 2,
              borderColor: "rgb(234,243,248)",
              fontSize: 15,
            }}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id}
            rows={surveyData}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            slotProps={{
              toolbar: { setSurveyData, setRowModesModel },
            }}
            getRowClassName={(params) =>
              params.row.is_negative ? "negative-row" : "positive-row"
            }
          />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {/* <CircularProgress /> */}
          <p
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginTop: "5px",
              marginLeft: "20px",
            }}
          >
            {initialText}
          </p>
        </div>
      )}
    </div>
  );
}

export default DataSurvey;
