import React, { useState, useEffect } from "react";
import Navbar from "../../../../../Components/Navbar";
import { fetchProfile } from "../../../../../utils/fetchProfile";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import { fetchProvinces } from "../DataUtil";
import {
  Button,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Autocomplete,
} from "@mui/material";
import { IconButton, Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { modalStyle } from "../../../../../Components/Modal/Index";
import { Slide, toast } from "react-toastify";
import "../../../../../style/setup.css";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  padding: "3px 10px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#069DAE",
  fontFamily: ["Roboto"].join(","),
  "&:hover": {
    backgroundColor: "#0696A6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0062cc",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

function AssignArea() {
  const accessToken = Cookies.get("access_token") || "";

  const [dataProfile, setDataProfile] = useState([]);
  const [dataProvinces, setDataProvinces] = useState([]);
  const [dataRegencies, setDataRegencies] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedRegency, setSelectedRegency] = useState(null);

  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [paginationInfo, setPaginationInfo] = useState({});
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(5);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchProfile(accessToken, setDataProfile, setNotAllowAccessPage, [
        "13",
        "14",
      ]);
      fetchProvinces(
        accessToken,
        setIsLoading,
        setDataProvinces,
        setSelectedProvince
      );
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [accessToken]);

  useEffect(() => {
    const handler = setTimeout(() => {
      fetchRegencies();
    }, 200);
    return () => {
      clearTimeout(handler);
    };
  }, [selectedProvince, page, size]);

  async function fetchRegencies() {
    try {
      const params = new URLSearchParams({
        page: page + 1,
        size: size,
      });

      if (selectedProvince) {
        params.append("province_code", selectedProvince.value);
      }

      const url = `${
        process.env.REACT_APP_API_URL
      }/api/v1/area/regencies?${params.toString()}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setDataRegencies(data.data);
        setPaginationInfo(data.pagination_info);
      } else {
        console.error("Error fetching Regencies:", response.statusText);
      }
    } catch (e) {
      console.error("Error fetching Regencies:", e);
    }
  }

  const handleOpenModal = (regency) => {
    setSelectedRegency(regency);
    setModalOpen(true);
  };

  const handleProvinceChange = (event, newValue) => {
    setSelectedProvince(newValue);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Setup Store Master"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allowed to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Setup Area"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >
          <BootstrapButton
            variant="contained"
            disableRipple
            onClick={() => (window.location.href = "/setup-area")}
            disabled={isLoading}
            className="productbutton"
          >
            <p>{isLoading ? "Loading..." : "Back to Setup Area"}</p>
          </BootstrapButton>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >
          <div>
            <Autocomplete
              sx={{ width: 300 }}
              value={selectedProvince}
              onChange={handleProvinceChange}
              options={dataProvinces.map((option) => ({
                label: option.name,
                value: option.code,
              }))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pilih Provinsi"
                  variant="outlined"
                />
              )}
            />
          </div>
        </div>

        <Paper
          sx={{
            mt: 2,
            ml: 2,
            mr: 2,
            mb: 2,
            border: 2,
            borderColor: "rgb(234,243,248)",
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "rgb(6,157,174)",
                      color: "#FFF",
                    }}
                  >
                    CODE
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "rgb(6,157,174)",
                      color: "#FFF",
                    }}
                  >
                    KOTA/KAB
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "rgb(6,157,174)",
                      color: "#FFF",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRegencies.length > 0 ? (
                  dataRegencies.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.code}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <Button>
                          <Tooltip
                            title="Assign"
                            onClick={() => handleOpenModal(row)}
                          >
                            <IconButton>
                              <FileUploadIcon />
                            </IconButton>
                          </Tooltip>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>No Data Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 25, 50, 100]}
            component="div"
            count={paginationInfo.total || 0}
            rowsPerPage={size}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleSizeChange}
          />
        </Paper>
      </div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 0 }}
      >
        <Box
          sx={{
            ...modalStyle,
            width: 500,
            margin: "0 auto",
            maxHeight: "700px",
            overflow: "auto",
            overflowX: "hidden",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title="Close Modal">
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setModalOpen(false)}
              sx={{ position: "absolute", top: 20, right: 30 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {/* Modal content */}
          {selectedRegency && (
            <div className="regency">
              <h2 style={{ marginBottom: "15px" }}>Assign Area</h2>
              <div>
                <TextField
                  label="REGION"
                  name="region"
                  // value={selectedRegency.code}
                  sx={{ width: "100%", mb: 2 }}
                />
              </div>
              <div>
                <TextField
                  label="WILAYAH"
                  name="wilayah"
                  // value={selectedRegency.name}
                  sx={{ width: "100%", mb: 2 }}
                />
              </div>
              {/* <div>
                <TextField
                  label="WILAYAH"
                  name="wilayah"
                  // value={selectedRegency.name}
                  sx={{ width: "100%", mb: 2 }}
                />
              </div> */}
              <div>
                <BootstrapButton
                  variant="contained"
                  disableRipple
                  sx={{ width: 400, mt: 2, height: 50, mb: 3 }}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </BootstrapButton>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default AssignArea;
