import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from "@mui/icons-material/UploadFile";

const DataTable = ({
  data,
  columns,
  totalCount,
  onPageChange,
  onRowsPerPageChange,
  page,
  rowsPerPage,
  dataProfile,
}) => {
  const handleEditClick = (row) => {
    // console.log("Edit clicked", row);
  };

  return (
    <div>
      <Paper
        sx={{
          mt: 2,
          ml: 2,
          mr: 2,
          mb: 2,
          border: 2,
          borderColor: "rgb(234,243,248)",
        }}
      >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                  }}
                  key={column.field}
                  align="center"
                >
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell
                style={{
                  fontWeight: "bold",
                  backgroundColor: "rgb(6,157,174)",
                  color: "#FFF",
                }}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column) => (
                  <TableCell key={column.field} align="center">
                    {row[column.field]}
                  </TableCell>
                ))}
                <TableCell align="center">
                  {dataProfile &&
                  (dataProfile.role_code === "A" ||
                    dataProfile.role_code === "COA") ? (
                    <>
                      <Tooltip
                        title="Edit Area"
                        onClick={() => handleEditClick(row)}
                      >
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Assign"
                        onClick={() =>
                          (window.location.href = "/setup-area/assign")
                        }
                      >
                        <IconButton>
                          <FileUploadIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
      </Paper>
    </div>
  );
};

export default DataTable;
