import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { fetchProfile } from "../../utils/fetchProfile";
import { DataGrid } from "@mui/x-data-grid";
import { useSearchParams, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TextField,
  Modal,
  MenuItem,
  Tooltip,
  ImageList,
  ImageListItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { BootstrapButton } from "../../Components/Button/BootstrapButton";
import { fetchStorePhotoBySurveyIdStoreCode } from "../../services/storeMasterService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
};

function ViewDataTrx() {
  const token = Cookies.get("access_token") || "";
  const [notAllowAccessPage, setNotAllowAccessPage] = useState(false);
  const [dataProfile, setDataProfile] = useState([]);

  const [modalTableData, setModalTableData] = useState([]);
  const [auditPlan, setAuditPlan] = useState(null);
  const [storeCode, setStoreCode] = useState(null);
  const [statusCode, setStatusCode] = useState("");
  const [surveyType, setSurveyType] = useState("");
  const [selectedModalStoreCode, setSelectedModalStoreCode] = useState("");
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [storePhotoDetail, setStorePhotoDetail] = useState([]);

  const [editKey, setEditKey] = useState("");
  const [editValue, setEditValue] = useState("");

  const [editKey2, setEditKey2] = useState("");
  const [editValue2, setEditValue2] = useState([]);

  const [modalOpenEditFunction, setModalOpenEditFunction] = useState(false);
  const [modalOpenEditFunctionRdcTipe, setModalOpenEditFunctionRdcTipe] =
    useState(false);
  const [modalOpenEditFunctionRdcStatus, setModalOpenEditFunctionRdcStatus] =
    useState(false);

  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [modalOpenLogCorrection, setModalOpenLogCorrection] = useState(false);

  const [dataTipeStokToko, setDataTipeStokToko] = useState([]);
  const [dataStatusPenjualan, setDataStatusPenjualan] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const isQc = searchParams.get("is_qc");

  const { surveyId, storeId } = useParams();

  const [questionType, setQuestionType] = useState("");

  useEffect(() => {
    fetchProfile(token, setDataProfile, setNotAllowAccessPage, []);
  }, []);

  const fetchAuditDetail = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/audit-plan/${storeId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        setAuditPlan(data.data);
        setStoreCode(data.data.store_code);
        setStatusCode(data.data.audit_status);
      } else {
        console.error("Failed to fetch audit detail:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAuditDetail();
  }, [token, storeId]);

  const fetchViewTrx = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/trx/rawdata/${surveyId}/${storeCode}?is_qc=${isQc}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setModalTableData(data.data);
        setSurveyType(data.detail.survey_type.toUpperCase());
      } else {
        console.error("Failed to fetch data:", response.statusText);
        setModalTableData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setModalTableData([]);
    }
  };

  const handleViewPhoto = async () => {
    // set photo detail
    setStorePhotoDetail(
      await fetchStorePhotoBySurveyIdStoreCode(token, surveyId, storeCode)
    );
  };

  useEffect(() => {
    if (storeCode) {
      handleViewPhoto();
      fetchViewTrx();
    }
  }, [storeCode]);

  const [columnsView, setColumnsView] = useState([]);

  useEffect(() => {
    if (modalTableData.length > 0) {
      const dynamicColumns = Object.keys(modalTableData[0])
        .filter((key) => key !== "id")
        .map((key) => ({
          field: key,
          headerClassName: "super-app-theme--header",
          headerName: key.toUpperCase(),
          width: 200,
          align: "left",
          headerAlign: "center",
          editable: false,
        }));

      const userRole = dataProfile.role_code;

      if (
        statusCode === "Closed" &&
        userRole !== "TL" &&
        surveyId !== "65cc7b99e0747712517ed5e3" && // exclude plossa project
        isQc !== "true"
      ) {
        dynamicColumns.push({
          field: "actions",
          headerClassName: "super-app-theme--header",
          headerName: "ACTIONS",
          headerAlign: "center",
          align: "center",
          width: 200,
          renderCell: (params) => {
            const { store_code, product_code } = params.row;

            const handleViewDataEdit = () => {
              openModalEdit(store_code, product_code);
            };

            return (
              <Tooltip title="View Data Edit" onClick={handleViewDataEdit}>
                <IconButton>
                  <BackupTableIcon />
                </IconButton>
              </Tooltip>
            );
          },
        });
      }

      setColumnsView(dynamicColumns);
    }
  }, [modalTableData, statusCode, dataProfile]);

  const openModalEdit = (storeCode, productCode) => {
    setModalOpenEdit(true);

    setSelectedModalStoreCode(storeCode);
    setSelectedProductCode(productCode);
  };

  const openModalLogCorrection = (storeCode, productCode) => {
    setModalOpenLogCorrection(true);
    setSelectedModalStoreCode(
      viewTrxInfoForLog.find((item) => item.label === "Kode Toko").value
    );
    setSelectedProductCode(
      viewTrxInfoForLog.find((item) => item.label === "Kode Produk").value
    );
  };

  // Fetch data for edit feature
  const [viewTrxEdit, setViewTrxEdit] = useState([]);
  const [viewTrxInfo, setViewTrxInfo] = useState([]);
  const [viewTrxInfoForLog, setViewTrxInfoForLog] = useState([]);
  const [viewTrxLog, setViewTrxLog] = useState([]);
  const [stateQuestionType, setStateQuestionType] = useState([]);

  const fetchViewTrxEdit = async () => {
    try {
      const urlViewTrx = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: surveyId,
        store_code: storeCode,
        product_code: selectedProductCode,
      };

      const response = await fetch(urlViewTrx, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const { data, data_info } = await response.json();

        const infoData = [
          { label: "Nama Toko", value: data_info.store_name },
          { label: "Kode Produk", value: data_info.product_code },
          { label: "Nama Produk", value: data_info.product_name },
        ];

        const infoDataForLog = [
          { label: "Kode Toko", value: data_info.store_code },
          { label: "Kode Produk", value: data_info.product_code },
        ];

        const displayedData = data.map((item) => ({
          fact: item.fact,
          QC: item.QC,
          Surveyor: item.Surveyor,
        }));

        displayedData.forEach((item) => {
          if (
            item.fact === "StatusPenjualan" &&
            item.Surveyor &&
            item.Surveyor.answer
          ) {
            item.Surveyor = item.Surveyor.answer;
          }

          if (
            item.fact === "TipeStokToko" &&
            item.Surveyor &&
            item.Surveyor.length > 0 &&
            item.Surveyor[0].answer
          ) {
            item.Surveyor = item.Surveyor.map((stok) => stok.answer).join(", ");
          }
        });

        setStateQuestionType(data_info.question[0].type)
        setViewTrxInfo(infoData);
        setViewTrxInfoForLog(infoDataForLog);
        setViewTrxEdit(displayedData);
      } else {
        setViewTrxInfo([]);
        setViewTrxEdit([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (modalOpenEdit) {
      fetchViewTrxEdit();
    }
  }, [modalOpenEdit, selectedModalStoreCode, selectedProductCode]);

  const handleEditRowFunction = async (fact, newValue) => {
    try {
      const editUrl = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: surveyId,
        store_code: storeCode,
        product_code: selectedProductCode,
        key: fact,
        value: newValue,
      };

      const response = await fetch(editUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        alert("Data berhasil diubah!");
        setModalOpenEditFunction(false);
        setModalOpenEditFunctionRdcTipe(false);
        setModalOpenEditFunctionRdcStatus(false);
        setEditValue(null);
        await fetchViewTrxEdit();
      } else if (
        response.status === 422 ||
        response.status === 400 ||
        response.status === 404
      ) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 500) {
        alert("Internal Server Error");
      } else {
        alert("Gagal mengubah data!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rdcEditOptions = async () => {
    try {
      let urlViewEditOptionsRdc = `${process.env.REACT_APP_API_URL}/api/v1/trx/rawdata-product-rdc-list?survey_id=${surveyId}`;

      const response = await fetch(urlViewEditOptionsRdc, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { data_tipe_stok_toko, data_status_penjualan } =
          await response.json();

        const processedDataTipeStokToko = data_tipe_stok_toko.map((item) => ({
          label: item.answer,
          value: item.code,
        }));
        setDataTipeStokToko(processedDataTipeStokToko);

        const processedDataStatusPenjualan = data_status_penjualan.map(
          (item) => ({
            label: item.answer,
            value: item.code,
          })
        );
        setDataStatusPenjualan(processedDataStatusPenjualan);
      } else {
        setDataStatusPenjualan([]);
        setDataTipeStokToko([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    rdcEditOptions();
  }, []);

  const fetchViewTrxLog = async () => {
    try {
      const urlViewTrx = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata/log/correction`;

      const requestBody = {
        survey_id: surveyId,
        store_code: storeCode,
        product_code: selectedProductCode,
      };

      const response = await fetch(urlViewTrx, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        const { data } = await response.json();
        setViewTrxLog(data);
      } else {
        setViewTrxLog([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (modalOpenLogCorrection) {
      fetchViewTrxLog();
    }
  }, [modalOpenLogCorrection, selectedModalStoreCode, selectedProductCode]);

    useEffect(() => {
    if (stateQuestionType) {
      setQuestionType(stateQuestionType);
    }
  },[stateQuestionType]);

  const handleEditRowFunctionTipeStok = async (fact2, newValue2) => {
    try {
      const editUrl = `${process.env.REACT_APP_API_URL}/api/v1/trx-product/rawdata`;

      const requestBody = {
        survey_id: surveyId,
        store_code: selectedModalStoreCode,
        product_code: selectedProductCode,
        key: fact2,
        value: newValue2,
      };

      const response = await fetch(editUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        alert("Data berhasil diubah!");
        setModalOpenEditFunctionRdcTipe(false);
        await fetchViewTrxEdit();
      } else if (
        response.status === 422 ||
        response.status === 400 ||
        response.status === 404
      ) {
        const { detail } = await response.json();
        alert(`${detail}`);
      } else if (response.status === 500) {
        alert("Internal Server Error");
      } else {
        alert("Gagal mengubah data!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openModalEditFunction = (fact) => {
    setEditKey(fact);

    setModalOpenEditFunction(true);
  };

  const openModalEditFunctionTipeStok = (fact) => {
    setEditKey2(fact);

    setModalOpenEditFunctionRdcTipe(true);
  };

  const openModalEditFunctionStatus = (fact) => {
    setEditKey(fact);

    setModalOpenEditFunctionRdcStatus(true);
  };

  const handleChangeTipeStokToko = (event) => {
    const {
      target: { value },
    } = event;
    setEditValue2(typeof value === "string" ? value.split(",") : value);
  };

  const columns_edit_rak1 = [
    {
      field: "fact",
      headerClassName: "super-app-theme--header",
      headerName: "FACT",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "QC",
      headerClassName: "super-app-theme--header",
      headerName: "QC",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "Surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        let tooltipContent;

        if (
          params.row.fact === "TotalStok" ||
          params.row.fact === "Penjualan"
        ) {
          tooltipContent = <IconButton></IconButton>;
        } else {
          tooltipContent = (
            <Tooltip
              title="Edit Data"
              onClick={() => openModalEditFunction(params.row.fact)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        }

        return tooltipContent;
      },
    },
  ];

  const columns_edit_rak2 = [
    {
      field: "fact",
      headerClassName: "super-app-theme--header",
      headerName: "FACT",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "QC",
      headerClassName: "super-app-theme--header",
      headerName: "QC",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "Surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        let tooltipContent;

        if (
          params.row.fact === "TotalStok" ||
          params.row.fact === "Penjualan" ||
          params.row.fact === "Keterangan"
        ) {
          tooltipContent = <IconButton></IconButton>;
        } else {
          tooltipContent = (
            <Tooltip
              title="Edit Data"
              onClick={() => openModalEditFunction(params.row.fact)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          );
        }

        return tooltipContent;
      },
    },
  ];

  const columns_edit_rdc = [
    {
      field: "fact",
      headerClassName: "super-app-theme--header",
      headerName: "FACT",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "QC",
      headerClassName: "super-app-theme--header",
      headerName: "QC",
      width: 210,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "Surveyor",
      headerClassName: "super-app-theme--header",
      headerName: "Surveyor",
      width: 380,
      align: "left",
      headerAlign: "center",
      editable: false,
    },
    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      headerName: "ACTIONS",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        let tooltipContent;

        if (params.row.fact === "Keterangan") {
          tooltipContent = <IconButton></IconButton>;
        } else {
          tooltipContent = (
            <Tooltip
              title="Edit Data"
              onClick={() => openModalEditFunction(params.row.fact)}
            >
              <IconButton>
                <EditIcon />
              </IconButton>
            </Tooltip>
          );

          if (params.row.fact === "StatusPenjualan") {
            tooltipContent = (
              <Tooltip
                title="Edit Data"
                onClick={() => openModalEditFunctionStatus(params.row.fact)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            );
          }

          if (params.row.fact === "TipeStokToko") {
            tooltipContent = (
              <Tooltip
                title="Edit Data"
                onClick={() => openModalEditFunctionTipeStok(params.row.fact)}
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            );
          }
        }

        return tooltipContent;
      },
    },
  ];

  const columnsViewLog =
    viewTrxLog.length > 0
      ? Object.keys(viewTrxLog[0])
          .filter((key) => key !== "id") // Exclude the column with field named "id"
          .map((key) => ({
            field: key,
            headerClassName: "super-app-theme--header",
            headerName: key.toUpperCase(),
            width: 200,
            align: "left",
            headerAlign: "center",
            editable: false,
          }))
      : [];

  if (notAllowAccessPage) {
    return (
      <div>
        <Navbar
          active="Data Survey"
          initial={dataProfile.initial}
          role_code={dataProfile.role_code}
        />
        <div>Not allow to access this page</div>
      </div>
    );
  }

  return (
    <div>
      <Navbar
        active="Data Survey"
        initial={dataProfile.initial}
        role_code={dataProfile.role_code}
      />
      <div className="content" style={{ padding: "1rem" }}>
        {modalTableData.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <div style={{ marginLeft: "20px", fontSize: "18px" }}>
              <p>Project: {auditPlan.audit_plan}</p>
              <p>Nama Toko: {auditPlan.store_name}</p>
            </div>
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={modalTableData}
                columns={columnsView}
                getRowId={(row) => row.id}
                sx={{
                  mt: 2,
                  ml: 2,
                  mr: 2,
                  "& .super-app-theme--header": {
                    backgroundColor: "rgb(6,157,174)",
                    color: "#FFF",
                    border: 2,
                    fontWeight: "bold",
                    borderColor: "rgb(234,243,248)",
                  },
                  border: 2,
                  borderColor: "rgb(234,243,248)",
                  fontSize: 15,
                }}
                getRowHeight={() => "auto"}
              />
            </div>
          </div>
        )}
        {storePhotoDetail?.length > 0 ? (
          <ImageList variant="masonry" cols={5} gap={8}>
            {storePhotoDetail.map((item) => (
              <ImageListItem key={item.id}>
                <img src={`${item.file}`} alt={item.id} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <p>Tidak ada foto</p>
        )}

        {/* view table for edit */}
        <Modal
          open={modalOpenEdit}
          onClose={() => setModalOpenEdit(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: 1300,
              margin: "0 auto",
              maxHeight: "700px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <Tooltip title="Close View Edit">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalOpenEdit(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <h2 style={{ marginLeft: "20px" }}>View Edit</h2>
            {viewTrxEdit.length > 0 ? (
              <div style={{ height: 600, width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {viewTrxInfo.map((item) => (
                      <p key={item.label} style={{ marginLeft: "20px" }}>
                        {item.label}: {item.value}
                      </p>
                    ))}
                  </div>
                  <BootstrapButton
                    style={{
                      color: "#fff",
                      height: "40px",
                      fontWeight: "600",
                    }}
                    onClick={openModalLogCorrection}
                  >
                    Log Correction
                  </BootstrapButton>
                </div>
                <DataGrid
                  rows={viewTrxEdit}
                  columns={
                    surveyType === "RAK1"
                      ? columns_edit_rak1
                      : surveyType === "RAK2"
                      ? columns_edit_rak2
                      : surveyType === "RDC" ||
                        surveyType === "RDC_MT" ||
                        surveyType === "RDC_PHARMACY" ||
                        surveyType === "RDC_MADAGASCAR"
                      ? columns_edit_rdc
                      : []
                  }
                  // columns={columns_edit_rak1}
                  getRowId={(row) => row.fact}
                  getRowHeight={() => "auto"}
                  sx={{
                    mt: 2,
                    ml: 2,
                    mr: 2,
                    "& .super-app-theme--header": {
                      backgroundColor: "rgb(6,157,174)",
                      color: "#FFF",
                      border: 2,
                      fontWeight: "bold",
                      borderColor: "rgb(234,243,248)",
                    },
                    border: 2,
                    borderColor: "rgb(234,243,248)",
                  }}
                />
              </div>
            ) : (
              <div>
                <p style={{ marginLeft: "20px" }}>Data Kosong</p>
              </div>
            )}
          </Box>
        </Modal>
        <Modal
          open={modalOpenEditFunction}
          onClose={() => setModalOpenEditFunction(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: 510,
              margin: "0 auto",
              maxHeight: "500px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <Tooltip title="Close View Edit">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalOpenEditFunction(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <h2 style={{}}>Masukan Nilai Baru</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                id="outlined-select-currency"
                label="Key"
                value={editKey}
                sx={{
                  width: 250,
                  height: 40,
                  color: "#000",
                }}
                size="small"
                disabled
              />
              <TextField
                id="outlined-select-currency"
                label="Value"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                sx={{
                  width: 250,
                  height: 40,
                }}
                size="small"
              />
            </div>
            <BootstrapButton
              style={{ width: "100%", height: "40px", marginTop: "10px" }}
              variant="contained"
              onClick={() => {
                handleEditRowFunction(editKey, editValue);
                setModalOpenEditFunction(false);
              }}
            >
              Submit
            </BootstrapButton>
          </Box>
        </Modal>
        {/* view table for edit rdc tipe stok */}
        <Modal
          open={modalOpenEditFunctionRdcTipe}
          onClose={() => setModalOpenEditFunctionRdcTipe(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: 510,
              margin: "0 auto",
              maxHeight: "500px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <Tooltip title="Close View Edit">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalOpenEditFunctionRdcTipe(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <h2 style={{}}>Masukan Nilai Baru</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                id="outlined-select-currency"
                label="Key"
                value={editKey2}
                sx={{
                  width: 250,
                  height: 40,
                  color: "#000",
                }}
                size="small"
                disabled
              />
              <FormControl
                sx={{ width: "100%", mb: 2, height: "40px", ml: "10px" }}
              >
                <InputLabel id="demo-multiple-name-label-group">
                  Value
                </InputLabel>
                <Select
                  sx={{ height: "40px" }}
                  labelId="demo-multiple-name-label-group"
                  id="demo-multiple-name-group"
                  multiple={questionType === "MUL_0"}
                  value={editValue2}
                  onChange={handleChangeTipeStokToko}
                  input={<OutlinedInput label="Value" />}
                >
                  {dataTipeStokToko.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <BootstrapButton
              style={{ width: "100%", height: "40px", marginTop: "10px" }}
              variant="contained"
              onClick={() => {
                handleEditRowFunctionTipeStok(editKey2, editValue2);
                setModalOpenEditFunctionRdcTipe(false);
              }}
            >
              Submit
            </BootstrapButton>
          </Box>
        </Modal>
        {/* view table for edit rdc status penjualan */}
        <Modal
          open={modalOpenEditFunctionRdcStatus}
          onClose={() => setModalOpenEditFunctionRdcStatus(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: 510,
              margin: "0 auto",
              maxHeight: "500px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <Tooltip title="Close View Edit">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalOpenEditFunctionRdcStatus(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <h2 style={{}}>Masukan Nilai Baru</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                id="outlined-select-currency"
                label="Key"
                value={editKey}
                sx={{
                  width: 250,
                  height: 40,
                  color: "#000",
                }}
                size="small"
                disabled
              />
              <TextField
                select
                id="outlined-select-currency"
                label="Value"
                value={editValue}
                onChange={(e) => setEditValue(e.target.value)}
                sx={{
                  width: 250,
                  height: 40,
                }}
                size="small"
              >
                {dataStatusPenjualan.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <BootstrapButton
              style={{ width: "100%", height: "40px", marginTop: "10px" }}
              variant="contained"
              onClick={() => {
                handleEditRowFunction(editKey, editValue);
                setModalOpenEditFunction(false);
              }}
            >
              Submit
            </BootstrapButton>
          </Box>
        </Modal>
        {/* view log correction */}
        <Modal
          open={modalOpenLogCorrection}
          onClose={() => setModalOpenLogCorrection(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: 1300,
              margin: "0 auto",
              maxHeight: "700px",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            <Tooltip title="Close Log Correction">
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setModalOpenLogCorrection(false)}
                sx={{ position: "absolute", top: 20, right: 30 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <h2 style={{ marginLeft: "20px" }}>Log Correction</h2>
            {viewTrxLog.length > 0 ? (
              <div style={{ height: 600, width: "100%" }}>
                <DataGrid
                  rows={viewTrxLog}
                  columns={columnsViewLog}
                  getRowId={(row) => row.id}
                  getRowHeight={() => "auto"}
                  sx={{
                    mt: 2,
                    ml: 2,
                    mr: 2,
                    "& .super-app-theme--header": {
                      backgroundColor: "rgb(6,157,174)",
                      color: "#FFF",
                      border: 2,
                      fontWeight: "bold",
                      borderColor: "rgb(234,243,248)",
                    },
                    border: 2,
                    borderColor: "rgb(234,243,248)",
                    fontSize: 15,
                  }}
                />
              </div>
            ) : (
              <div>
                <p style={{ marginLeft: "20px" }}>Data Kosong</p>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default ViewDataTrx;
